export const questions = [
  {
    question: "Não sei programar. A Code Start é para mim?",
    answer:
      "Sim! Dentro do treinamento temos guias de estudo para você direcionar o seu aprendizado. Além disso, teremos conteúdos sobre como estudar e exercícios do básico ao avançado de HTML/CSS/Javascript",
  },
  {
    question: "O acesso é vitalício?",
    answer:
      "Sim! Você vai poder assistir quando e onde quiser. Além disso, terá sempre atualizações e você não precisará pagar a mais por tudo que vier a ter no treinamento!",
  },
  {
    question: "Como funciona os projetos que são desenvolvidos na Code Start?",
    answer:
      "Todos os meses nós desenvolvemos um projeto prático para você exercitar o seu conhecimento e estar sempre atualizado com o que o mercado pede.",
  },
  {
    question:
      "Não estou no nível avançado, mas também não estou no básico. Conseguirei tirar dúvidas?",
    answer:
      "Sim, a Code Start possui uma área de dúvidas para que vocês enviem todas as dúvidas de vocês. Além de responder a sua dúvida, ela pode gerar um conteúdo para os próximos alunos. Nesse caso, gravaremos um vídeo explicando sobre o assunto e postaremos lá dentro.",
  },
  {
    question:
      "Sou iniciante, sei muito pouco do básico. Minhas dúvidas serão respondidas",
    answer:
      "Com certeza! O nosso treinamento é para todos e não existe “dúvida melhor” ou “dúvida pior”. Se você tiver qualquer dúvida, nós iremos te responder!",
  },
  {
    question: "Há conteúdos sobre mercado de trabalho?",
    answer:
      "Sim. Na Code Start você encontrará conceitos que são cobrados no mercado de trabalho além do quesito técnico para você se preparar ainda mais para os processos seletivos.",
  },
  {
    question: "Quais são as linguagens de programação trabalhadas?",
    answer:
      "A linguagem principal da Code Start hoje é o javascript, tanto para o frontend quanto para o backend.",
  },
  {
    question: "Os conteúdos gravados serão complementados?",
    answer:
      "Sim, todas as vezes que encontrarmos assuntos interessantes para ajudar na jornada de vocês, nós iremos gravar um conteúdo e colocar dentro do treinamento.",
  },
];
